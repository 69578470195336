import { render, staticRenderFns } from "./InvestorLogin.vue?vue&type=template&id=8c805236&scoped=true&"
import script from "./InvestorLogin.vue?vue&type=script&lang=js&"
export * from "./InvestorLogin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8c805236",
  null
  
)

export default component.exports