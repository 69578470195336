<template>
    <investor-login-component/>
</template>

<script>
    import InvestorLoginComponent from "@/components/auth/InvestorLoginComponent";
    
    export default {
        name: "InvestorLogin",
        title: "Autenticación - Inversionista | Private",
        components: {InvestorLoginComponent}
    }
</script>

<style scoped>

</style>