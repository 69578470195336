<template>
    <v-container fluid class="container--fluid fill-height text-left pa-0 backcolor" style="width: 100%">
        <v-row>
            <v-img src="@/assets/images/header-bg.jpg"
                   class="align-end"
                   transition="slide-x-transition"
                   width="100%" height="250">
                <v-row align="end" justify="center">
                    <v-col cols="12">
                        <div class="fill-height container--fluid px-5 px-md-16 pb-10">
                            <span class="font-weight-thin mx-5 text-h5 text-md-h2 white--text">
                               Bienvenido Inversionista
                            </span>
                            <v-divider dark class="mt-2"/>
                        </div>
                    </v-col>
                </v-row>
            </v-img>
        </v-row>
        <v-row>
            <v-container fluid class="fill-height container--fluid">
                <v-row justify="center">
                    <v-col cols="12" lg="6">
                        <div class="my-10 mb-16">
                            <h2 class="font-weight-bold blue-grey--text text--darken-2">Acceso</h2>
                            <v-form ref="form" v-model="valid" action="/admin/investing" lazy-validation>
                                <v-text-field
                                    v-model="email"
                                    :rules="emailRules"
                                    class="mt-4"
                                    label="E-mail"
                                    outlined
                                    required
                                ></v-text-field>
                                <v-text-field
                                    v-model="password"
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    :counter="10"
                                    :rules="passwordRules"
                                    :type="showPassword ? 'text' : 'password'"
                                    label="Password"
                                    outlined
                                    required
                                    @click:append="showPassword=!showPassword">
                                </v-text-field>
                                <div class="d-block d-sm-flex align-center mb-4 mb-sm-0" v-if="false">
                                    <v-checkbox
                                        v-model="checkbox"
                                        label="Recordarme?"
                                        required
                                    ></v-checkbox>
                                    <div class="ml-auto">
                                        <h6 v-show="true" class="subtitle-1">
                                            No tiene una cuenta aún?
                                            <a class href="/#">Regístrese</a>
                                        </h6>
                                        <a href="javascript:void(0)" class="link" v-if="false">Olvidó la contraseña?</a>
                                    </div>
                                </div>
                                <v-btn
                                    :disabled="!valid"
                                    block
                                    class="mr-4 mt-10 white--text"
                                    color="deep-orange"
                                    submit
                                    @click="submit">
                                    Entrar
                                </v-btn>
                            </v-form>
                        </div>
                    </v-col>
                </v-row>
            </v-container>
        </v-row>
        <v-row justify="center">
            <v-dialog
                v-model="dialogError"
                max-width="600"
                persistent>
                <v-card>
                    <v-card-title class="text-h6 primary lighten-2">
                        <span class="headline">Error de autentificación</span>
                    </v-card-title>

                    <v-card-text>
                        <div class="text-h6 pa-10">{{ messageError }}!</div>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn text @click.stop="closeDialogError">
                            Cerrar
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row>
            <v-dialog
                v-model="progressDialog"
                hide-overlay
                persistent
                width="300">
                <v-card
                    color="primary"
                    dark>
                    <v-card-text>
                        Validando las credenciales...
                        <v-progress-linear
                            class="mb-0"
                            color="white"
                            indeterminate
                        ></v-progress-linear>
                    </v-card-text>
                </v-card>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script>
import loginService from "@/providers/LoginService";

export default {
    name: "InvestorLoginComponent",
    data: () => ({
        valid: true,
        showPassword: false,
        progressDialog: false,
        dialogError: false,
        messageError: '',
        email: '',
        checkbox: false,
        emailRules: [
            v => !!v || "El correo electrónico es obligatorio",
            v => /.+@.+\..+/.test(v) || "El correo electrónico debe ser válido"
        ],
        password: '',
        passwordRules: [
            v => !!v || "La contraseña es obligatoria",
            v => (v && v.length >= 10) || "La contraseña debe tener al menos de 10 caracteres"
        ]
    }),
    methods: {
        goHome() {
            this.$store.dispatch('clearState');
            this.$router.push({path: "/homepage"});
        },
        submit() {
            this.$refs.form.validate();
            if (this.$refs.form.validate(true)) {
                this.progressDialog = true;
                this.doLogin();
            }
        },
        doLogin() {
            let passport = {
                email: this.email,
                password: this.password
            };
            loginService.loginUser(passport).then(
                response => {
                    // console.log('response: ',response);
                    this.progressDialog = false;
                    this.dialogError = false;
                    if (response.valid) {
                        this.closeDialogError();
                        if (response.roles !== "INVERSIONISTA") {
                            this.messageError = "El usuario autentificado no es un Inversionista. " +
                                "Vuelva a intentar con otro que contenga el rol de Inversionista";
                            this.dialogError = true;
                        } else {
                            loginService.redirectToPage(response.token, 'investor');
                        }
                    } else {
                        // console.log('response.valid: ',response.valid);
                        // console.log("messagedData: ", this.messagedData);
                        this.messageError = response.messageText;
                        this.dialogError = true;
                    }
                }
            );
        },
        closeDialogError() {
            this.dialogError = false;
            this.messageError = '';
        }
    }
}
</script>

<style scoped>
.backcolor {
    background-color: #ffffff;
    margin-top: 0px;
}
</style>